import { Box } from "@mui/joy";
import { useEffect, useMemo, useRef, useState } from "react";
import { FixedSizeGrid as Grid } from "react-window";
import { IQualita } from "../../../models";
import { matrixify } from "../../../lib/utils";
import { MaterialeGridCell } from "./MaterialeGridCell";

const calcColumnsFromWidth = (width: number): number => {
  if (width > 1280) return 5;
  if (width > 900) return 4;
  if (width > 700) return 3;
  if (width > 500) return 2;
  return 1;
};

interface ISelectMaterialeGridProps {
  materiali: IQualita[];
  onView: (id: number) => void;
}

export default function SelectMaterialeGrid({
  materiali,
  onView,
}: ISelectMaterialeGridProps) {
  const parent = useRef<Element>();
  const [gridHeight, setGridHeight] = useState<number>(0);
  const [gridWidth, setGridWidth] = useState<number>(0);
  const [columns, setColumns] = useState<number>(1);

  const matrix = useMemo(
    () => matrixify(materiali, columns),
    [materiali, columns]
  );
  const setGridDimensions = () => {
    const element = parent.current;
    if (!!element) {
      const { height, width } = element.getBoundingClientRect();
      setGridHeight(height);
      setGridWidth(width);
      setColumns(calcColumnsFromWidth(width));
    }
  };
  const columnWidth = useMemo(
    () => (gridWidth - 20) / columns,
    [gridWidth, columns]
  );
  useEffect(() => {
    if (parent.current) {
      const observer = new ResizeObserver((_entries) => setGridDimensions());
      observer.observe(parent.current);
      setGridDimensions();
      // Cleanup function
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <Box
      sx={{
        position: "absolute",
        height: "100%",
        width: "100%",
        "& .Grid": {
          boxSizing: "border-box",
          overflowX: "hidden",
        },
      }}
      ref={parent}
    >
      <Grid
        className="Grid"
        itemData={matrix}
        columnCount={columns}
        columnWidth={columnWidth}
        height={gridHeight}
        width={gridWidth}
        rowCount={matrix.length}
        rowHeight={columnWidth}
      >
        {(props) => MaterialeGridCell({ ...props, onView })}
      </Grid>
    </Box>
  );
}
