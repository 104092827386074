import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/joy";
import { useMemo } from "react";
import { isNotEmpty } from "../../../lib/utils";
import {
  IDettaglio,
  IFondo,
  IMateriale,
  IQualita,
  IStatoLavorazione,
} from "../../../models";
import { EOptionType, IFilterOption } from "../../../models/utlis";
import SelectMaterialeFilters from "./SelectMaterialeFilters";
import SelectMaterialeGrid from "./SelectMaterialeGrid";

interface ISelectMaterialeProps {
  dettagli: IDettaglio[];
  tipologie: IMateriale[];
  colori: IFondo[];
  lavorazioni: IStatoLavorazione[];
  spessoriDisponibili: number[];
  filters: IFilterOption[];
  onSetFilters: (filters: IFilterOption[]) => void;
}

export default function SelectMateriale({
  dettagli,
  tipologie,
  colori,
  filters,
  lavorazioni,
  spessoriDisponibili,
  onSetFilters,
}: ISelectMaterialeProps) {
  const onView = (id: number) => {
    const _filters = [...filters].filter(
      (opt) => !(opt.type === EOptionType.qualita)
    );
    if (!!id)
      onSetFilters([
        ..._filters,
        {
          type: EOptionType.qualita,
          numericValue: id,
        },
      ]);
    else onSetFilters(_filters);
  };

  const materialiToDisplay = useMemo(() => {
    const materiali: IQualita[] = dettagli
      .map((dettaglio) => dettaglio.lotto?.qualita)
      .filter(isNotEmpty)
      .reduce(
        (prev: IQualita[], curr: IQualita) =>
          !prev.find((l) => l.id === curr.id) ? [...prev, curr] : prev,
        []
      );
    return materiali.sort((a, b) => (a.nome > b.nome ? 1 : -1));
  }, [dettagli]);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        "@media screen and (min-width: 992px)": {
          flexDirection: "row",
        },
      }}
    >
      <Box
        sx={{
          display: "none",
          "@media screen and (min-width: 992px)": {
            position: "relative",
            display: "flex",
            height: "100%",
            width: "25%",
          },
        }}
      >
        <SelectMaterialeFilters
          tipologie={tipologie}
          colori={colori}
          lavorazioni={lavorazioni}
          spessoriDisponibili={spessoriDisponibili}
          filters={filters}
          onSetFilters={onSetFilters}
        />
      </Box>
      <Box
        sx={{
          "@media screen and (min-width: 992px)": {
            display: "none",
          },
        }}
      >
        <AccordionGroup>
          <Accordion>
            <AccordionSummary>
              <Typography sx={{ color: "rgb(86, 185, 58)" }}>Filtri</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SelectMaterialeFilters
                tipologie={tipologie}
                colori={colori}
                lavorazioni={lavorazioni}
                spessoriDisponibili={spessoriDisponibili}
                filters={filters}
                onSetFilters={onSetFilters}
              />
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
      </Box>
      <Box sx={{ flex: 1, position: "relative" }}>
        <SelectMaterialeGrid materiali={materialiToDisplay} onView={onView} />
      </Box>
    </Box>
  );
}
