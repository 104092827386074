import { Box, Button, Modal, ModalClose, Sheet } from "@mui/joy";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { ILotto } from "../../../../../models";
import PackingListToPrint from "./packingListToPrint";

export interface IPrintDialogProps {
  lotto: ILotto;
  isOpen: boolean;
  onClose: () => void;
}

function DettaglioPrintDialog({ lotto, isOpen, onClose }: IPrintDialogProps) {
  const componentRef = useRef<HTMLDivElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef?.current ?? null,
    documentTitle: `packing_list_${lotto.codice}`,
  });
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: "80%",
          height: "80%",
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
          backgroundColor: "#fff",
        }}
      >
        <ModalClose variant="outlined" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <Box sx={{ flex: 1, p: 2 }}>
            <PackingListToPrint
              ref={componentRef}
              lotto={lotto}
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Button onClick={handlePrint}>STAMPA</Button>
          </Box>
        </Box>
      </Sheet>
    </Modal>
  );
}

export default DettaglioPrintDialog;
