import { Box, Button, Typography } from "@mui/joy";
import { ILotto } from "../../../models";
import { useState } from "react";
import DettaglioPrintDialog from "./dialogs/print/printDialog";
import { isNotEmpty } from "../../../lib/utils";

interface ILottoInformationsProps {
  lotto: ILotto | null;
  onBack: () => void;
}

export default function LottoInformations({
  lotto,
  onBack,
}: ILottoInformationsProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const numeroLastre = lotto?.dettagli?.reduce(
    (prev, dett) => dett.pezzi + prev,
    0
  );
  const misure: string[] = Array.from(
    new Set(
      lotto?.dettagli?.map(
        (dett) => `${dett.misX} x ${dett.misY} x ${dett.misZ} cm`
      ) ?? []
    )
  );

  const finiture: string[] = Array.from(
    new Set(
      lotto?.dettagli
        ?.map((dett) => dett.stato_lavorazione?.nome)
        .filter(isNotEmpty) ?? []
    )
  );
  const onClick = () => {
    setIsOpen(true);
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        position: "relative",
        pb: 1,
        boxSizing: "border-box",
        backgroundColor: "rgba(0,0,0,.20)",
      }}
    >
      <Box sx={{ px: 4, pt: 1, width: "100%", boxSizing: "border-box" }}>
        <Typography
          level="body-md"
          onClick={onBack}
          sx={{
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          &lt; indietro
        </Typography>
        <Box sx={{ display: "flex", py: 2 }}>
          <Typography level="h4">Lotto:</Typography>
          <Typography level="h4" sx={{ ml: 2, color: "#fff" }}>
            {lotto?.codice}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", py: 1 }}>
          <Typography>Tipologia:</Typography>
          <Typography sx={{ ml: 2, color: "#fff" }}>
            {lotto?.materiale?.nome}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", py: 1 }}>
          <Typography>Materiale:</Typography>
          <Typography sx={{ ml: 2, color: "#fff" }}>
            {lotto?.qualita?.nome}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", py: 1 }}>
          <Typography>Lastre:</Typography>
          <Typography sx={{ ml: 2, color: "#fff" }}>{numeroLastre}</Typography>
        </Box>
        <Box sx={{ display: "flex", py: 1 }}>
          <Typography>Misure:</Typography>
          <Box>
            {misure.map((misura, index) => (
              <Typography key={index} sx={{ ml: 2, color: "#fff" }}>
                {misura}
              </Typography>
            ))}
          </Box>
        </Box>
        <Box sx={{ display: "flex", py: 1 }}>
          <Typography>Finiture:</Typography>
          <Box>
            {finiture.map((finitura, index) => (
              <Typography key={index} sx={{ ml: 2, color: "#fff" }}>
                {finitura}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
      <Box sx={{ px: 4, pt: 4, width: "100%", boxSizing: "border-box" }}>
        <Button onClick={onClick}>Richiedi Packing List</Button>
      </Box>
      {lotto && (
        <DettaglioPrintDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          lotto={lotto}
        ></DettaglioPrintDialog>
      )}
    </Box>
  );
}
