import { Box, Container, CssVarsProvider } from "@mui/joy";
import { Outlet } from "react-router-dom";
import theme from "../theme";

export default function MainContainer() {
  return (
    <CssVarsProvider theme={theme}>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Container
          maxWidth={false}
          disableGutters={true}
          sx={{ flex: 1, display: "flex", flexDirection: "column" }}
        >
          <Outlet />
        </Container>
      </Box>
    </CssVarsProvider>
  );
}
