import { extendTheme } from "@mui/joy";

export default extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          plainColor: "#56B93A",
          solidBg: "#56B93A",

          solidHoverBg: "#3f8b2a",
          solidActiveBg: "#2c611d",
        },
        success: {
          solidBg: "#2DA44E",
          solidHoverBg: "#2C974B",
          solidActiveBg: "#298E46",
        },
        neutral: {
          plainColor: "#fff",
          plainHoverColor: "#fff",
          plainActiveColor: "#56B93A",
          plainHoverBg: "rgba(0, 0, 0, 0.45)",
          plainActiveBg: "rgba(0, 0, 0, 0.8)",
          softBg: "rgba(0,0,0,.20)",
        },
        focusVisible: "rgba(86, 185, 58, 0.7)",
        background: {
          surface: "rgba(0, 0, 0, 0.35)",
          popup: "rgba(0, 0, 0, 0.65)",
        },
        text: {
          primary: "#56B93A",
          secondary: "#56B93A",
        },
      },
    },
  },
  focus: {
    default: {
      outlineWidth: "3px",
    },
  },

  fontFamily: {
    display: '"europa", Verdana, Helvetica, sans-serif', // applies to `h1`–`h4`
    body: '"europa", Verdana, Helvetica, sans-serif', // applies to `title-*` and `body-*`
  },
});
