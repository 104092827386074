import { Box } from "@mui/joy";
import { useMemo } from "react";
import { createFilter } from "../../lib/filters";
import {
  IDettaglio,
  IFondo,
  IMateriale,
  IQualita,
  IStatoLavorazione,
} from "../../models";
import { EOptionType, IFilterOption } from "../../models/utlis";
import SelectLotto from "./components/SelectLotto";
import SelectMateriale from "./components/SelectMateriale";

export interface IListProps {
  filters: IFilterOption[];
  setFilters: (filters: IFilterOption[]) => void;
  dettagli: IDettaglio[];
}

export default function List({ filters, setFilters, dettagli }: IListProps) {
  

  const showLotti: boolean = useMemo(
    () => !!filters.find((filter) => filter.type === EOptionType.qualita),
    [filters]
  );

  const materialeSelected: IQualita | null = useMemo(() => {
    const filterMateriale = filters.find(
      (filter) => filter.type === EOptionType.qualita
    );
    if (!filterMateriale) return null;
    const filter = createFilter([filterMateriale]);
    const dettagliArray = dettagli.filter((item) => filter(item));
    return dettagliArray?.[0]?.lotto?.qualita ?? null;
  }, [filters, dettagli]);

  const filteredDettagli = useMemo(() => {
    if (!!filters && !!filters.length) {
      const filter = createFilter(filters);
      return dettagli.filter((item) => filter(item));
    } else {
      return dettagli;
    }
  }, [dettagli, filters]);

  const tipologie = useMemo(() => {
    const _tipologie: IMateriale[] = [];
    dettagli.forEach((dettaglio) => {
      const tipologia = dettaglio.lotto?.materiale;
      if (tipologia && !_tipologie.find((m) => m.id === tipologia.id)) {
        _tipologie.push(tipologia);
      }
    });
    return _tipologie;
  }, [dettagli]);

  const colori = useMemo(() => {
    const _colori: IFondo[] = [];
    dettagli.forEach((dettaglio) => {
      const colore = dettaglio.lotto?.fondo;
      if (colore && !_colori.find((c) => c.id === colore.id)) {
        _colori.push(colore);
      }
    });
    return _colori.sort((a, b) =>
      a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1
    );
  }, [dettagli]);

  const lavorazioni = useMemo(() => {
    const _lavorazioni: IStatoLavorazione[] = [];
    dettagli.forEach((dettaglio) => {
      const lavorazione = dettaglio.stato_lavorazione;
      if (lavorazione && !_lavorazioni.find((l) => l.id === lavorazione.id)) {
        _lavorazioni.push(lavorazione);
      }
    });
    return _lavorazioni.sort((a, b) =>
      a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1
    );
  }, [dettagli]);

  const spessoriDisponibili = useMemo(() => {
    const _spessori: number[] = [];
    dettagli.forEach((dettaglio) => {
      const spessore = dettaglio.misZ;
      if (spessore && !_spessori.find((s) => s === spessore)) {
        _spessori.push(spessore);
      }
    });
    return _spessori.sort((a, b) => (a > b ? 1 : -1));
  }, [dettagli]);

  

  return (
    <Box sx={{ height: "100%" }}>
      {!showLotti && (
        <SelectMateriale
          dettagli={filteredDettagli}
          tipologie={tipologie}
          colori={colori}
          lavorazioni={lavorazioni}
          spessoriDisponibili={spessoriDisponibili}
          filters={filters}
          onSetFilters={setFilters}
        />
      )}
      {showLotti && (
        <SelectLotto
          dettagli={filteredDettagli}
          materialeSelected={materialeSelected}
          lavorazioni={lavorazioni}
          spessoriDisponibili={spessoriDisponibili}
          filters={filters}
          onSetFilters={setFilters}
        />
      )}
    </Box>
  );
}
