import {
  AspectRatio,
  Box,
  Modal,
  ModalClose,
  Sheet,
  Typography,
} from "@mui/joy";
import { ILotto } from "../../../../../models";
import Carousel from "react-material-ui-carousel";
import { useEffect, useMemo, useRef, useState } from "react";
import { BASE_URL, IMAGE_LABEL_FORMULA } from "../../../../../config";
import { getImageName } from "../../../../../lib/functions";

const IMAGE_URL = `${BASE_URL}image/`;

export interface ICarouselDialogProps {
  lotto: ILotto;
  selectedImageId: number | null;
  isOpen: boolean;
  onClose: () => void;
}

function CarouselDialog({
  lotto,
  selectedImageId,
  isOpen,
  onClose,
}: ICarouselDialogProps) {
  const [height, setHeight] = useState<number>();
  const [imageHeight, setImageHeight] = useState<number>();
  const carousel = useRef<HTMLElement>();

  const images = useMemo(
    () => lotto.images?.sort((a, b) => a.slab - b.slab) ?? [],
    [lotto]
  );

  const startIndex = useMemo(
    () => lotto.images?.findIndex((image) => image.id === selectedImageId) ?? 0,
    [lotto, selectedImageId]
  );

  const getSize = () => {
    const newHeight = carousel?.current?.clientHeight;
    setHeight(newHeight);
    setImageHeight((newHeight ?? 0) - 48);
  };
  useEffect(() => {
    if (isOpen) window.addEventListener("resize", getSize);
    else window.removeEventListener("resize", getSize);
    setTimeout(getSize, 500);
  }, [isOpen]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: "80%",
          height: "80%",
          borderRadius: "md",
          p: 6,
          boxShadow: "lg",
        }}
      >
        <ModalClose variant="outlined" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            position: "relative",
          }}
          ref={carousel}
        >
          <Carousel
            sx={{ flex: 1 }}
            index={startIndex}
            autoPlay={false}
            navButtonsAlwaysVisible={true}
            animation="slide"
          >
            {images.map((image, index) => (
              <Box key={index} sx={{ height }}>
                <AspectRatio
                  objectFit="contain"
                  minHeight={imageHeight}
                  maxHeight={imageHeight}
                >
                  <img
                    src={`${IMAGE_URL}${image.hdPath}`}
                    alt={`image_${index}`}
                  />
                </AspectRatio>
                <Typography
                  sx={{
                    mb: 2,
                    textAlign: "center",
                    "@media screen and (min-width: 992px)": {
                      display: "none",
                    },
                  }}
                  level="title-sm"
                >
                  {lotto ? getImageName(image, lotto, IMAGE_LABEL_FORMULA) : ""}
                </Typography>
                <Typography
                  sx={{
                    mb: 2,
                    textAlign: "center",
                    display: "none",
                    "@media screen and (min-width: 992px)": {
                      display: "block",
                    },
                  }}
                  level="h1"
                >
                  {lotto ? getImageName(image, lotto, IMAGE_LABEL_FORMULA) : ""}
                </Typography>
              </Box>
            ))}
          </Carousel>
        </Box>
      </Sheet>
    </Modal>
  );
}

export default CarouselDialog;
