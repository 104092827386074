import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/joy";
import { useMemo } from "react";
import {
  IDettaglio,
  ILotto,
  IQualita,
  IStatoLavorazione,
} from "../../../models";
import { EOptionType, IFilterOption } from "../../../models/utlis";
import SelectLottoFilters from "./SelectLottoFilters";
import SelectLottoGrid from "./SelectLottoGrid";
import { useNavigate } from "react-router-dom";

interface ISelectLottoProps {
  dettagli: IDettaglio[];
  materialeSelected: IQualita | null;
  lavorazioni: IStatoLavorazione[];
  spessoriDisponibili: number[];
  filters: IFilterOption[];
  onSetFilters: (filters: IFilterOption[]) => void;
}

export default function SelectLotto({
  dettagli,
  materialeSelected,
  lavorazioni,
  spessoriDisponibili,
  filters,
  onSetFilters,
}: ISelectLottoProps) {
  const navigate = useNavigate();
  const onView = (id: number) => {
    navigate(`${id}`);
  };
  const onBack = () => {
    const _filters = [...filters].filter(
      (opt) => !(opt.type === EOptionType.qualita)
    );
    onSetFilters(_filters);
  };
  const lottiToDisplay = useMemo(() => {
    const _lotti: ILotto[] = [];
    dettagli.forEach((dettaglio) => {
      const lotto = dettaglio.lotto;
      if (lotto) {
        const existingLotto = _lotti.find((l) => l.id === lotto.id);
        if (!existingLotto) {
          lotto.dettagli = [dettaglio];
          _lotti.push(lotto);
        } else {
          existingLotto.dettagli?.push(dettaglio);
        }
      }
    });
    return _lotti;
  }, [dettagli]);
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        "@media screen and (min-width: 992px)": {
          flexDirection: "row",
        },
      }}
    >
      <Box
        sx={{
          display: "none",
          "@media screen and (min-width: 992px)": {
            position: "relative",
            display: "flex",
            height: "100%",
            width: "25%",
          },
        }}
      >
        <Box
          sx={{
            px: 1,
            position: "absolute",
            width: "100%",
            "@media screen and (min-width: 992px)": {
              px: 4,
              pt: 1,
            },
          }}
        >
          <Typography
            level="body-md"
            onClick={onBack}
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            &lt; indietro
          </Typography>
          <Typography sx={{ mt: 2 }} level="h4">
            Materiale: {materialeSelected?.nome}
          </Typography>
        </Box>
        <SelectLottoFilters
          spessoriDisponibili={spessoriDisponibili}
          lavorazioni={lavorazioni}
          filters={filters}
          onSetFilters={onSetFilters}
        />
      </Box>
      <Box
        sx={{
          "@media screen and (min-width: 992px)": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "block",
            position: "relative",
            height: "75px",
            "@media screen and (min-width: 992px)": {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              px: 1,
              position: "absolute",
              width: "100%",
              "@media screen and (min-width: 992px)": {
                px: 4,
                pt: 1,
              },
            }}
          >
            <Typography
              level="body-md"
              onClick={onBack}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              &lt; indietro
            </Typography>
            <Typography sx={{ mt: 2 }} level="h4">
              Materiale: {materialeSelected?.nome}
            </Typography>
          </Box>
        </Box>
        <AccordionGroup>
          <Accordion>
            <AccordionSummary>
              <Typography sx={{ color: "rgb(86, 185, 58)" }}>Filtri</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SelectLottoFilters
                spessoriDisponibili={spessoriDisponibili}
                lavorazioni={lavorazioni}
                filters={filters}
                onSetFilters={onSetFilters}
              />
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
      </Box>
      <Box sx={{ flex: 1, position: "relative" }}>
        <SelectLottoGrid lotti={lottiToDisplay} onView={onView} />
      </Box>
    </Box>
  );
}
