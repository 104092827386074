import { IDettaglio, ILotto, ILottoImage } from "../models";
import { searchInSource } from "./utils";

export const getTotalMQ = (
  rows: IDettaglio[],
  decimalToDisplay: number = 2,
  decimalToCalc?: number
): string =>
  Number(
    rows
      .filter((row) => row.tipo_lotto?.nome === "Lastre")
      .reduce((previousValue, currentValue) => {
        return (
          previousValue +
          (!!decimalToCalc
            ? parseFloat(currentValue.quantitaUM.toFixed(decimalToCalc))
            : currentValue.quantitaUM)
        );
      }, 0)
      .toFixed(decimalToDisplay)
  ).toLocaleString("it-IT");

export const getTotalNumberOfSlabs = (rows: IDettaglio[]): number =>
  rows
    .filter((row) => row.tipo_lotto?.nome === "Lastre")
    .reduce(
      (previousValue, currentValue) => previousValue + currentValue.pezzi,
      0
    );

export const getTotalNumberOfBlocks = (rows: IDettaglio[]): number =>
  rows
    .filter((row) => row.tipo_lotto?.nome === "Blocco")
    .reduce(
      (previousValue, currentValue) => previousValue + currentValue.pezzi,
      0
    );

export const getImageName = (
  image: ILottoImage,
  lotto: ILotto,
  formula: string
): string => {
  const dettaglio = lotto.dettagli?.find(
    (dettaglio) =>
      image.slab >= dettaglio.lastreDa && image.slab <= dettaglio.lastreA
  );
  const source = { image, lotto, dettaglio };
  const matches = formula?.match(/\[(.+?)\]/gi) ?? [];
  const values = matches?.map((match) => searchInSource(source, match));
  for (let i = 0; i < matches.length; ++i) {
    const match = matches[i];
    const value = values[i];
    formula = formula.replace(match, value);
  }
  return formula;
};
