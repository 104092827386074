import { BASE_URL } from "../config";
import { IDettaglio } from "../models";
import { IBaseError, IListResponse } from "../models/utlis";

export const getDettaglioList = async (): Promise<
  IListResponse<IDettaglio>
> => {
  const url = `${BASE_URL}/dettaglio/listPublished`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const json: IListResponse<IDettaglio> | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IListResponse<IDettaglio>;
};
