import { HashRouter, Route, Routes } from "react-router-dom";
import MainContainer from "../container";
import List from "./list";
import Item from "./lotto";
import { IFilterOption } from "../models/utlis";
import { useEffect, useState } from "react";
import { IDettaglio } from "../models";
import { getDettaglioList } from "../fetch";

export default function Router() {
  const [filters, setFilters] = useState<IFilterOption[]>([]);
  const [dettagli, setDettagli] = useState<IDettaglio[]>([]);
  useEffect(() => {
    let mounted = true;
    getDettaglioList().then((result) => {
      if (mounted) {
        setDettagli(result.items);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);
  return (
    <HashRouter>
      <Routes>
        <Route element={<MainContainer />}>
          <Route
            path="/"
            element={
              <List
                filters={filters}
                setFilters={setFilters}
                dettagli={dettagli}
              ></List>
            }
          ></Route>
          <Route path="/:id" element={<Item></Item>}></Route>
        </Route>
      </Routes>
    </HashRouter>
  );
}
