import { AspectRatio, Box, Typography } from "@mui/joy";
import mockBackground from "../../../assets/background.jpg";
import { ILotto, ILottoImage } from "../../../models";
import { useMemo, useState } from "react";
import { BASE_URL, IMAGE_LABEL_FORMULA } from "../../../config";
import { getImageName } from "../../../lib/functions";
import CarouselDialog from "./dialogs/carousel/carouselDialog";

const IMAGE_URL = `${BASE_URL}image/`;

export interface ILottoImagesProps {
  lotto: ILotto | null;
}

export default function LottoImages({ lotto }: ILottoImagesProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedImageId, setSelectedImageId] = useState<number | null>(null);
  const cover: ILottoImage | undefined = useMemo(
    () => lotto?.images?.find((image) => image.isCover) ?? lotto?.images?.[0],
    [lotto]
  );

  const otherImages: ILottoImage[] = useMemo(() => {
    return (
      lotto?.images
        ?.filter((image) => !image.isCover)
        .sort((a, b) => a.slab - b.slab) ?? []
    );
  }, [lotto]);

  const onView = (imageId?: number) => {
    if (!imageId) return;
    setSelectedImageId(imageId);
    setIsOpen(true);
  };
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          "@media screen and (min-width: 992px)": {
            position: "absolute",
            overflow: "auto",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            "@media screen and (min-width: 992px)": { pr: 16 },
          }}
        >
          <Box sx={{ flex: 1, pl: 2 }}>
            <Box sx={{ position: "relative" }}>
              <AspectRatio objectFit="contain" sx={{ p: 1 }}>
                <img
                  src={cover ? `${IMAGE_URL}${cover.mdPath}` : mockBackground}
                  alt="cover"
                />
              </AspectRatio>
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  backgroundColor: "rgba(86, 185, 58, 0.7)",
                  opacity: 0,
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  cursor: "pointer",
                  transition: "opacity .15s ease-in-out",
                  "&:hover": {
                    opacity: 0.7,
                  },
                }}
                onClick={() => onView(cover?.id)}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    mb: 2,
                    display: "none",
                    "@media screen and (min-width: 992px)": {
                      display: "block",
                    },
                  }}
                  level="h2"
                >
                  {lotto && cover
                    ? getImageName(cover, lotto, IMAGE_LABEL_FORMULA)
                    : ""}
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    mb: 2,
                    "@media screen and (min-width: 992px)": { display: "none" },
                  }}
                  level="title-md"
                >
                  {lotto && cover
                    ? getImageName(cover, lotto, IMAGE_LABEL_FORMULA)
                    : ""}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                "@media screen and (min-width: 992px)": {
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                },
              }}
            >
              {otherImages.map((image, index) => (
                <Box key={index} sx={{ position: "relative" }}>
                  <AspectRatio
                    sx={{
                      p: 1,
                      "@media screen and (min-width: 992px)": {
                        width: "400px",
                      },
                    }}
                    objectFit="contain"
                  >
                    <img
                      src={`${IMAGE_URL}${image.mdPath}`}
                      alt={`image_${index}`}
                    />
                  </AspectRatio>
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      backgroundColor: "rgba(86, 185, 58, 0.7)",
                      opacity: 0,
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "center",
                      cursor: "pointer",
                      transition: "opacity .15s ease-in-out",
                      "&:hover": {
                        opacity: 0.7,
                      },
                    }}
                    onClick={() => onView(image.id)}
                  >
                    <Typography sx={{ color: "#fff", mb: 2 }} level="title-md">
                      {lotto
                        ? getImageName(image, lotto, IMAGE_LABEL_FORMULA)
                        : ""}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      {lotto && (
        <CarouselDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          selectedImageId={selectedImageId}
          lotto={lotto}
        ></CarouselDialog>
      )}
    </Box>
  );
}
